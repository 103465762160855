.footerContainer {
  width: 100vw;
  min-height: 80px;
  padding: 20px 16px 10px 16px;
  background: #282828 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column-reverse;
    gap: 16px;
    padding: 16px 0 10px 0;
  }
}

.footerLeft {
  display: flex;
  align-items: center;
  gap: 40px;
}

.copyright {
  color: #939598;
  font-size: 14px;
  line-height: 1.4;
}

.footerMenuContainer {
  display: flex;
  align-items: center;
}
.footerMenuContainer > a {
  width: 18px;
  height: 18px;
  margin: 0 16px;
  text-indent: -999px;
  overflow: hidden;
  display: inline-block;
}

.twitter {
  background: url(https://launchdarkly-marketingsite.cdn.prismic.io/launchdarkly-marketingsite/0481c606-c2c6-4309-ae32-a2247089da6f_icon-twitter.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50)
    center center no-repeat;
}
.linkedin {
  background: url(https://launchdarkly-marketingsite.cdn.prismic.io/launchdarkly-marketingsite/8b512fb1-6ecc-4e7f-bfe2-368f1a29898b_icon-LinkedIn.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50)
    center center no-repeat;
}
.meetup {
  background: url(https://launchdarkly-marketingsite.cdn.prismic.io/launchdarkly-marketingsite/01b89e82-95a2-495c-91de-4b6710e6161d_icon-meetup.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50)
    center center no-repeat;
}
.youtube {
  background: url(https://launchdarkly-marketingsite.cdn.prismic.io/launchdarkly-marketingsite/276be984-0bd0-44ed-9e32-44a21a0c4fac_icon-youtube.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50)
    center center no-repeat;
}
