.headerBg {
    width: 100vw;
    background: #282828 !important;
    height: 80px;
    padding: 0 12px 0 0;
}

@media only screen and (min-width: 768px) {
    .headerBg {
        padding: 0 24px 0 0;
    }
    .logo {
        margin-left: 24px;
    }
}

.headerWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    max-height: 70px;
    padding: 0 10px;
    margin: 5px 0 0 12px;
}

@media only screen and (max-width: 768px) {
    .logo {
        max-height: 50px;
    }
}

.headerLink {
    color: #ffffff;
    font-size: 16px;
    margin: auto 16px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.linkIcon {
    width: 16px;
    height: 16px;
}
